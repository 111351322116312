export const environment = {
  production: true,
  appUrl: "https://your_production_url/",
  authEnv: "production",
  name: "production",
  // TODO: Setup your application's authentication variables: see
  // https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  auth: {
    clientID: "provide_client_id",
    domain: "zonar-login.auth0.com",
    audience: "http://api.zonarsystems.net/",
    // These come from the Core APIs as your application is built.
    applicationId: "provide-local-app-uuid-from-core-api",
    defaultZonarRole: "provide-local-app-uuid-from-core-api",
    useRefreshTokens: true,
  },
  datadog: {
    applicationId: "0fcf7c22-4f1c-40fc-9e26-46143a4f165f",
    clientToken: "pub0e79e7dac50f1491904e54954495bee9",
  },
  region: "NA",
  i18n: {
    supportedLanguages: ["en", "en-US", "de-DE"],
    defaultLanguage: "en-US",
  },
};
