import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ZonarUICoreModule } from '@zonar-ui/core';
import { ZonarUiProgressSpinnerModule } from '@zonar-ui/progress-spinner';
import { ZonarUiI18nModule, TranslateModule, TranslateLoader } from '@zonar-ui/i18n';
import { CoreModule } from 'src/app/core/core.module';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ZonarUICoreModule,
    ZonarUiI18nModule,
    FlexLayoutModule,
    ZonarUiProgressSpinnerModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientID,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirectUri: window.location.origin,
      scope: 'openid profile email',
      useRefreshTokens: true,
      maxAge: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          `${environment.appUrl}/*`,
        ]
      }
    })

      // TODO: Build application modules and include them here.
  ],
  providers: [{ provide: 'applicationId', useValue: environment.auth.applicationId },
  {
    provide: 'supportedLanguages',
    useValue: environment.i18n.supportedLanguages
  },{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },{provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage},{provide: 'logoutReturnToUrl', useValue: window.location.origin},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


